@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  @variants responsive {
    .modal-xl {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 450px;
      max-width: 500px;
      padding: 44px;
    }
    .modal {
      top: auto;
      transform: none;
      min-width: unset;
      bottom: 0;
      padding: 16px;
      left: 0;
      right: 0;
    }

    .logo-small {
      width: 70px;
      height: 28px;
    }
    .dot-small {
      width: 14px;
      height: 14px;
    }

    .card-mobile {
      width: 327px;
      height: 318px;
    }

    .card {
      width: 530px;
      height: 410px;
    }

    .vinyl-mobile {
      position: absolute;
      width: 231px;
      height: 231px;
      left: calc(50% - 231px/2);
      top: calc(50% - 231px/2);
    }

    .vinyl {
      position: absolute;
      width: 338px;
      height: 338px;
      left: calc(50% - 338px/2);
      top: calc(50% - 338px/2);
    }

    .large-vinyl-image {
      width: 338px;
      height: 338px;
    }

    .large-vinyl-image-mobile {
      width: 231px;
      height: 231px;
    }
  }
}


@import '~@kyivstarteam/ks-react-design-system-public/Styles/mixins';
@import '~@kyivstarteam/ks-react-design-system-public/Styles/bosons';
@import '~@kyivstarteam/ks-react-design-system-public/Styles/atoms';
@import '~@kyivstarteam/ks-react-design-system-public/Styles/colors';
@import '~@kyivstarteam/ks-react-design-system-public/Styles/typography';
@import '~@kyivstarteam/ks-react-design-system-public/index.css';
@import 'variables';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Averta Kyivstar, Roboto, Helvetica Neue, sans-serif;
  background: $radio-palette-background;
  background-image: url('/_next/image?url=%2Fimages%2Fbackground.webp&w=1440&q=100');
  background-repeat: repeat;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.phone-number {
  background: $cyan-90 !important;
  border-radius: 8px;
  color: $b-con-color-interactive-primary;
}

.phone-number .icon * {
  fill: $cyan-90 !important;
}
