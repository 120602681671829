@import '~@kyivstarteam/ks-react-design-system-public/Styles/bosons';
@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: #0087E6;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 327px;
  max-width: 400px;
}

.message {
  font-style: normal;
  font-weight: 300;
  font-size: $b-con-font-size-m;
  line-height: $b-con-line-height-l;
  color: $white-100;
}
