@import '~@kyivstarteam/ks-react-design-system-public/Styles/bosons';
@import '../../styles/variables';
.header {
  font-style: normal;
  font-weight: 600;
  font-size: $b-con-font-size-xl;
  line-height: $b-con-line-height-xl;
  color: $white-100;
}

.message {
  font-style: normal;
  font-weight: 400;
  font-size: $b-con-font-size-m;
  line-height: $b-con-line-height-l;
  color: $white-100;
}
