@import '~@kyivstarteam/ks-react-design-system-public/Styles/bosons';
@import '../../styles/variables';

.overlay {
  position: fixed;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: var(--kds-spacing-lplus);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--kds-color-overlay);
  inset: 0;
}

.container {
  background: rgb(39, 38, 38);
  border: 1px solid rgb(60, 59, 59);
  border-radius: 32px;
  order: 0;
  position: fixed;
}

.circle {
  width: 72px;
  height: 72px;
  border-radius: 72px;
  background: rgba(255, 255, 255, 0.1);
}

.textDescription {
  color: rgba(255, 255, 255, 0.6);
}

.closeButton {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.closeButton span {
  color: #FFFFFF;
}
