@import '~@kyivstarteam/ks-react-design-system-public/Styles/bosons';

.H1 {
  font-style: normal;
  //font-weight: $b-con-font-weight-bold;
  font-size: $b-con-font-size-3xl;
  line-height: $b-con-line-height-3xl;
  color: $white-100;
}

.heading {
  font-style: normal;
  font-weight: $b-con-font-weight-semibold;
  font-size: $b-con-font-size-xs;
  line-height: $b-con-line-height-s;
  color: $white-100;
}

.title {
  font-style: normal;
  font-weight: $b-con-font-weight-bold;
  font-size: $b-con-font-size-m;
  line-height: $b-con-line-height-l;
  color: $white-100;
}

.copyright {
  font-style: normal;
  font-weight: $b-con-font-weight-regular;
  font-size: $b-con-font-size-xs;
  line-height: $b-con-line-height-s;
  color: $grey-50;
}

.legal {
  @extend .copyright;
  color: $grey-30;
}

.lbl {
  font-style: normal;
  font-weight: $b-con-font-weight-bold;
  font-size: $b-con-font-size-m;
  line-height: $b-con-line-height-l;
  color: $white-100;
}
